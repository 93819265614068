export interface IRetornoRequisicao {
    codigoRegistro: number;
    status: number;
    mensagem: string;
}

export enum EStatusRetornoRequisicao {
    Sucesso = 1,
    Alerta = 2,
    Erro = 3
}
