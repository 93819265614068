import dayjs from 'dayjs';
import { obterItem, removerItem, salvarItem } from '@/core/gerentes/GerenteLocalStorage';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';

export default class GerenteAutenticacao {
  private chaveIdentificadorContrante = 'SS-NEOX-ID-CONTRATANTE';

  private chaveTokenAutenticado = 'SS-NEOX-TOKEN';

  private chaveExpiracaoToken = 'SS-NEOX-TOKEN-EXPIRACAO';

  public salvarIdentificadorContratante(identificadorContratante: string): void {
    salvarItem(this.chaveIdentificadorContrante, identificadorContratante);
  }

  public removerIdentificadorContratante(): void {
    removerItem(this.chaveIdentificadorContrante);
  }

  public salvarDadosAutenticacao(tokenAutenticacao: string, dataExpiracaoToken: string): void {
    salvarItem(this.chaveTokenAutenticado, tokenAutenticacao);
    salvarItem(this.chaveExpiracaoToken, dataExpiracaoToken);
  }

  public removerDadosAutenticacao(): void {
    removerItem(this.chaveIdentificadorContrante);
    removerItem(this.chaveTokenAutenticado);
    removerItem(this.chaveExpiracaoToken);
    removerItem('SS-NEOX-EMPRESA-OPERACAO');
  }

  public existeIdentificadorContratante(): boolean {
    const identificadorContratante = obterItem(this.chaveIdentificadorContrante);
    return UtilitarioGeral.valorValido(identificadorContratante);
  }

  public existeToken(): boolean {
    const tokenAutenticado = obterItem(this.chaveTokenAutenticado);
    return UtilitarioGeral.valorValido(tokenAutenticado);
  }

  public existeValidadeToken(): boolean {
    const dataExpiracaoToken = obterItem(this.chaveExpiracaoToken);
    return UtilitarioGeral.valorValido(dataExpiracaoToken);
  }

  public obtemTokenAutenticado(): string {
    return `${obterItem(this.chaveTokenAutenticado)}`;
  }

  public obtemIdentificadorContratante(): string {
    return `${obterItem(this.chaveIdentificadorContrante)}`;
  }

  public tokenValido(): boolean {
    if (this.existeToken()) {
      return false;
    }
    return false;
  }

  public verificaAutenticacao(): boolean {
    if (!this.existeIdentificadorContratante()) {
      return false;
    }

    if (!this.existeToken()) {
      return false;
    }

    if (!this.existeValidadeToken()) {
      return false;
    }

    return true;
  }

  public atualizarToken(): boolean {
    let dataExpiracaoToken = obterItem(this.chaveExpiracaoToken) as any;
    dataExpiracaoToken = dayjs(dataExpiracaoToken).format('YYYY-MM-DD HH:mm:ss');
    const dataAtual = dayjs().format('YYYY-MM-DD HH:mm:ss');

    if (dataAtual > dataExpiracaoToken) {
      return true;
    }
    return false;
  }
}
