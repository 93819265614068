import { reactive } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { IDTOUsuarioPermissoesDados } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoesDados';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IOption, IOptionString } from '../models/AntDesign/IOption';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IPreferencia, IPreferenciaEmpresa } from '@/models/Entidades/MeuSistema/Preferencias/Preferencia';
import { ETipoPreferencia } from '@/models/Enumeradores/MeuSistema/ETipoPreferencia';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import { IPropriedadeConsulta } from '../models/Consulta/PropriedadeConsulta';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { IItemGenerico } from '../models/IItemGenerico';
import { IItemConsultaRapida } from '../models/Consulta/IItemConsultaRapida';
import router from '@/router';
import { ITelaOperacao } from '../models/ITelaOperacao';
import { IOrdenacao } from '../models/Consulta/IOrdenacao';
import { IFiltroGenericoAdicionado } from '../models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IColumn } from '../models/AntDesign/IColumn';
import { IItemConsultaRapidaString } from '../models/Consulta/IItemConsultaRapidaString';

export interface ITelaBase {
  titulo: string;
  identificadorRecurso: string;
  identificadorPermissao: string;
  apresentarEmpresas: boolean;
  empresasDisponiveis: Array<IOption>;
  empresasSelecionadas: Array<number>;
  listaPermissoes: Array<IDTOUsuarioPermissoes>;
  listaPermissoesDados: Array<IDTOUsuarioPermissoesDados>;
  permissaoDados: IDTOUsuarioPermissoesDados;
  propriedadesConsulta: Array<IPropriedadeConsulta>;
  preferencias: Array<IPreferencia>;
  fetching: boolean;
  debounce: number;
  carregando: boolean;
  apresentarBarraProgresso: boolean;
  ordenacaoSelecionada: Array<IOrdenacao>;
}

export interface IRetornoTelaBase{
  telaBase: ITelaBase;
  obterEmpresas(apenasAtivas: boolean):Promise<IOption[]>;
  obterPermissoes(tipoPermissao: ETipoPermissao): Promise<void>;
  preencherEmpresasDisponiveis():Promise<void>;
  preencherEmpresasComEstrategiaPermissaoDados(tipoPermissaoDados: EPermissaoDados, verificarCadastroCompartilhado: boolean):Promise<void>;
  preencherEmpresasComEstrategiaPermissao():Promise<void>;
  preencherPermissoesDados(permissoesDados: IDTOUsuarioPermissoesDados[]):Promise<void>;
  preencherPermissoes(permissoes: IDTOUsuarioPermissoes[]):Promise<void>;
  defineEmpresasSelecionadasComPermissao():Promise<void>;
  defineEmpresasSelecionadasCadastroCompartilhado(codigoEmpresa:number):Promise<void>;
  filtrarPermissaoDadosUsuario(codigoEmpresa: number):IDTOUsuarioPermissoesDados;
  filtrarPermissaoDadosUsuarioMultiEmpresas(empresas: Array<number>):Promise<IDTOUsuarioPermissoesDados>;
  verificaConceitoParaApresentarEmpresas():void;
  verificaAutorizacao(empresa: number, identificadorAutorizacao: string, redirecionarSemPermissao: boolean): boolean;
  apresentarMensagemSucesso(mensagem: string):void;
  apresentarMensagemAlerta(mensagem: string):void;
  apresentarMensagemErro(mensagem: string):void;
  montaObjetoPreferencia(chave: string, valor: string, empresas: number[]): IPreferencia;
  salvarPreferencias(mensagemSucesso: string, chave: string, valor: string, empresas: number[]): Promise<void>;
  salvarPreferenciasFiltros(empresas:number[], filtrosAdicionados: IFiltroGenericoAdicionado[]):Promise<void>;
  salvarPreferenciasOrdenacao(empresas:number[], ordenacaoSelecionada: IOrdenacao[]):Promise<void>;
  salvarPreferenciasGrade(empresas:number[], colunas: IColumn[]):Promise<void>;
  carregarPreferenciasFiltros(preferencias: IPreferencia[]):IFiltroGenericoAdicionado[];
  carregarPreferenciasOrdenacao(preferencias: IPreferencia[]):IOrdenacao[];
  carregarPreferenciasGrade(colunasPadrao:IColumn[], preferencias: IPreferencia[]):IColumn[];
  adicionarAtalho(identificadorRecurso: string, empresas:number[]): Promise<void>;
  removerAtalho(identificadorRecurso: string, empresas:number[]): Promise<void>;
  montaOpcoesComListaConsultaRapida(listaItens: IItemConsultaRapida[]) : IOption[];
  montaOpcoesComListaConsultaRapidaString(listaItens: IItemConsultaRapidaString[]) : IOptionString[];
  montaOpcoesComListaItemGenerico(listaItens: IItemGenerico[]) : IOption[];
  montaOpcoesComListaItemGenericoTexto(listaItens: IItemGenerico[]) : IOptionString[];
  telaOperacaoIncluir():ITelaOperacao;
  telaOperacaoEditar(codigo: number):ITelaOperacao;
  preencherDadosRota(): void;
}

export function useTelaBase():IRetornoTelaBase {
  const telaBase = reactive({
    titulo: '',
    identificadorRecurso: '',
    identificadorPermissao: '',
    apresentarEmpresas: true,
    empresasDisponiveis: [] as IOption[],
    empresasSelecionadas: [] as number[],
    listaPermissoes: [] as IDTOUsuarioPermissoes[],
    listaPermissoesDados: [] as IDTOUsuarioPermissoesDados[],
    permissaoDados: {} as IDTOUsuarioPermissoesDados,
    propriedadesConsulta: [] as IPropriedadeConsulta[],
    preferencias: [] as IPreferencia[],
    fetching: false,
    debounce: 0,
    carregando: false,
    apresentarBarraProgresso: false,
    ordenacaoSelecionada: [] as IOrdenacao[],
  });

  async function obterEmpresas(apenasAtivas: boolean):Promise<IOption[]> {
    let empresas = await new ServicoSistema().obterDadosEmpresas(false);
    let listaEmpresas : IOption[] = [];
    if (apenasAtivas) {
      empresas = empresas.filter((c) => c.statusAssinatura === 1);
      empresas = empresas.filter((c) => c.statusAssinatura === 2);
    }

    if (UtilitarioGeral.validaLista(empresas)) {
      listaEmpresas = empresas.map((item) => ({
        label: UtilitarioGeral.montaExibicaoEmpresa(item.codigoExibicao, item.nomeExibicao),
        value: item.codigoEmpresa,
      }));
    }

    return listaEmpresas;
  }

  async function obterPermissoes(tipoPermissao: ETipoPermissao):Promise<void> {
    const servicoSistema = new ServicoSistema();

    switch (tipoPermissao) {
      case ETipoPermissao.Dados:
        telaBase.listaPermissoesDados = await servicoSistema.obterPermissoesDadosUsuario(telaBase.identificadorPermissao);
        break;
      case ETipoPermissao.Autorizacoes:
        telaBase.listaPermissoes = await servicoSistema.obterPermissoesAutorizacoesUsuario(telaBase.identificadorPermissao);
        break;
      case ETipoPermissao.Relatorios:
        telaBase.listaPermissoes = await servicoSistema.obterPermissoesRelatoriosUsuario(telaBase.identificadorPermissao);
        break;
      case ETipoPermissao.Widgets:
        telaBase.listaPermissoes = await servicoSistema.obterPermissoesWidgetsUsuario(telaBase.identificadorPermissao);
        break;
      default:
        telaBase.listaPermissoes = [];
        telaBase.listaPermissoesDados = [];
        break;
    }
  }

  async function preencherEmpresasDisponiveis():Promise<void> {
    telaBase.empresasDisponiveis = storeSistema.state.empresasDisponiveis.map((item) => ({
      label: UtilitarioGeral.montaExibicaoEmpresa(item.codigoExibicao, item.nomeExibicao),
      value: item.codigoEmpresa,
    }));
  }

  async function preencherEmpresasComEstrategiaPermissaoDados(tipoPermissaoDados: EPermissaoDados, verificarCadastroCompartilhado: boolean):Promise<void> {
    const servicoSistema = new ServicoSistema();
    const empresas = await servicoSistema.obterEmpresasComEstrategiaPermissaoDados(telaBase.identificadorRecurso, tipoPermissaoDados, telaBase.identificadorPermissao, telaBase.listaPermissoesDados, verificarCadastroCompartilhado);
    telaBase.empresasDisponiveis = empresas.map((item) => ({
      label: UtilitarioGeral.montaExibicaoEmpresa(item.codigoExibicao, item.nomeExibicao),
      value: item.codigoEmpresa,
    }));
  }

  async function preencherEmpresasComEstrategiaPermissao():Promise<void> {
    const servicoSistema = new ServicoSistema();
    const empresas = await servicoSistema.obterEmpresasComEstrategiaPermissao(telaBase.identificadorPermissao, telaBase.listaPermissoes);
    telaBase.empresasDisponiveis = empresas.map((item) => ({
      label: UtilitarioGeral.montaExibicaoEmpresa(item.codigoExibicao, item.nomeExibicao),
      value: item.codigoEmpresa,
    }));
  }

  async function preencherPermissoesDados(permissoesDados: IDTOUsuarioPermissoesDados[]):Promise<void> {
    telaBase.listaPermissoesDados = permissoesDados;
  }

  async function preencherPermissoes(permissoes: IDTOUsuarioPermissoes[]):Promise<void> {
    telaBase.listaPermissoes = permissoes;
  }

  async function defineEmpresasSelecionadasComPermissao():Promise<void> {
    telaBase.empresasSelecionadas = [];
    if (telaBase.empresasDisponiveis.length > 0) {
      telaBase.empresasDisponiveis.forEach((empresa) => {
        telaBase.empresasSelecionadas.push(empresa.value);
      });
    }
  }

  async function defineEmpresasSelecionadasCadastroCompartilhado(codigoEmpresa:number):Promise<void> {
    telaBase.empresasSelecionadas = [];
    const servicoSistema = new ServicoSistema();
    const empresasCompartilhandoCadastro = await servicoSistema.obterEmpresasComCadastrosCompartilhados(telaBase.identificadorRecurso);
    if (UtilitarioGeral.validaLista(empresasCompartilhandoCadastro)) {
      const empresaSelecionada = empresasCompartilhandoCadastro.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (empresaSelecionada !== undefined) {
        if (empresaSelecionada.compartilhar) {
          empresasCompartilhandoCadastro.forEach((dadosEmpresa) => {
            if (dadosEmpresa.compartilhar) {
              telaBase.empresasSelecionadas.push(dadosEmpresa.codigoEmpresa);
            }
          });
        } else {
          telaBase.empresasSelecionadas.push(codigoEmpresa);
        }
      }
    } else {
      telaBase.empresasSelecionadas.push(codigoEmpresa);
    }
  }

  function verificaConceitoParaApresentarEmpresas() {
    telaBase.apresentarEmpresas = telaBase.empresasDisponiveis.length > 1;
  }

  function verificaAutorizacao(empresa: number, identificadorAutorizacao: string, redirecionarSemPermissao: boolean): boolean {
    if (UtilitarioGeral.validaLista(telaBase.listaPermissoes)) {
      const autorizacaoEncontrada = telaBase.listaPermissoes.find((c) => c.codigoEmpresa === empresa && c.identificadorPermissao === identificadorAutorizacao);
      if (UtilitarioGeral.objetoValido(autorizacaoEncontrada)) {
        return true;
      }
    }
    if (redirecionarSemPermissao) {
      router.push({ name: 'SemPermissao' });
    }
    return false;
  }

  function filtrarPermissaoDadosUsuario(codigoEmpresa: number):IDTOUsuarioPermissoesDados {
    return new ServicoSistema().filtrarPermissaoDadosUsuario(telaBase.identificadorPermissao, telaBase.listaPermissoesDados, codigoEmpresa);
  }

  async function filtrarPermissaoDadosUsuarioMultiEmpresas(empresas: Array<number>):Promise<IDTOUsuarioPermissoesDados> {
    const permissoes = await new ServicoSistema().filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.identificadorPermissao, telaBase.listaPermissoesDados, empresas);
    return permissoes;
  }

  function apresentarMensagemSucesso(mensagem: string) : void{
    message.success(mensagem);
  }

  function apresentarMensagemAlerta(mensagem: string) : void{
    message.warning(mensagem);
  }

  function apresentarMensagemErro(mensagem: string) : void{
    message.error(mensagem);
  }

  function montaOpcoesComListaConsultaRapida(listaItens: IItemConsultaRapida[]) : IOption[] {
    let listaOpcoes:IOption[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.textoIdentificacao,
      value: Number(item.codigo),
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaConsultaRapidaString(listaItens: IItemConsultaRapidaString[]) : IOptionString[] {
    let listaOpcoes:IOptionString[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.textoIdentificacao,
      value: item.codigo,
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaItemGenerico(listaItens: IItemGenerico[]) : IOption[] {
    let listaOpcoes:IOption[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.descricao,
      value: Number(item.identificador),
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaItemGenericoTexto(listaItens: IItemGenerico[]) : IOptionString[] {
    let listaOpcoes:IOptionString[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.descricao,
      value: item.identificador,
    }));

    return listaOpcoes;
  }

  function montaObjetoPreferencia(chave: string, valor: string, empresas:number[]): IPreferencia {
    const preferencia:IPreferencia = {
      codigo: 0,
      tipo: ETipoPreferencia.Usuario,
      identificadorRecurso: telaBase.identificadorRecurso,
      chave,
      valor,
      empresas: [],
    };

    empresas.forEach((empresa) => {
      const empresaPreferencia:IPreferenciaEmpresa = {
        codigo: 0,
        codigoPreferencia: 0,
        codigoEmpresa: empresa,
      };
      preferencia.empresas.push(empresaPreferencia);
    });

    return preferencia;
  }
  async function salvarPreferencias(mensagemSucesso: string, chave: string, valor: string, empresas:number[]) {
    const retorno = await new ServicoSistema().salvarPreferenciaUsuario(montaObjetoPreferencia(chave, valor, empresas));
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      if (mensagemSucesso !== '') {
        apresentarMensagemSucesso(mensagemSucesso);
      }
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  async function salvarPreferenciasFiltros(empresas:number[], filtrosAdicionados: IFiltroGenericoAdicionado[]):Promise<void> {
    await salvarPreferencias('Filtros salvos com sucesso!', 'busca-avancada-filtros',
      JSON.stringify(filtrosAdicionados), empresas);
  }

  async function salvarPreferenciasOrdenacao(empresas:number[], ordenacaoSelecionada: IOrdenacao[]):Promise<void> {
    await salvarPreferencias('Ordenação salva com sucesso!', 'busca-avancada-ordenacao',
      JSON.stringify(ordenacaoSelecionada), empresas);
  }

  async function salvarPreferenciasGrade(empresas:number[], colunas: IColumn[]):Promise<void> {
    const colunasPersistencia:IColumn[] = []; let posicao = 0;
    colunas.forEach((coluna) => {
      const colunaNova = coluna;
      colunaNova.position = posicao;
      colunasPersistencia.push(colunaNova);
      posicao += 1;
    });
    await salvarPreferencias('Colunas salvas com sucesso!', 'personalizacao-colunas',
      JSON.stringify(colunasPersistencia.sort((c) => c.position)), empresas);
  }

  function carregarPreferenciasFiltros(preferencias: IPreferencia[]):IFiltroGenericoAdicionado[] {
    let filtrosAdicionados: IFiltroGenericoAdicionado[] = [];
    if (preferencias !== null) {
      const preferenciaFiltros = preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-filtros'));
      if (preferenciaFiltros !== undefined && preferenciaFiltros !== null) {
        filtrosAdicionados = JSON.parse(preferenciaFiltros.valor);
      }
    }

    return filtrosAdicionados;
  }

  function carregarPreferenciasOrdenacao(preferencias: IPreferencia[]):IOrdenacao[] {
    let ordenacaoSelecionada: IOrdenacao[] = [];
    if (preferencias !== null) {
      const preferenciaOrdenacao = preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-ordenacao'));
      if (preferenciaOrdenacao !== undefined && preferenciaOrdenacao !== null) {
        ordenacaoSelecionada = JSON.parse(preferenciaOrdenacao.valor);
      }
    }

    return ordenacaoSelecionada;
  }

  function carregarPreferenciasGrade(colunasPadrao:IColumn[], preferencias: IPreferencia[]):IColumn[] {
    if (preferencias !== null) {
      const preferenciaColunas = preferencias.find(((preferencia) => preferencia.chave === 'personalizacao-colunas'));
      if (preferenciaColunas !== undefined && preferenciaColunas !== null) {
        if (preferenciaColunas.valor !== '[]' && preferenciaColunas.valor !== '') {
          const colunasDB: IColumn[] = JSON.parse(preferenciaColunas.valor);
          const colunasNovasPreferencias: IColumn[] = [];
          colunasPadrao.forEach((coluna) => {
            const preferenciaCol = colunasDB.find(((colunaDB) => colunaDB.key === coluna.key));
            const colunaNova:IColumn = coluna;
            if (preferenciaCol !== undefined && preferenciaCol !== null) {
              colunaNova.visible = preferenciaCol.visible;
              colunaNova.fixed = preferenciaCol.fixed;
              colunaNova.align = preferenciaCol.align;
              colunaNova.width = preferenciaCol.width;
              colunaNova.position = preferenciaCol.position;
              colunasNovasPreferencias.push(colunaNova);
            }
          });
          return colunasNovasPreferencias.sort((x, y) => x.position - y.position);
        }
      }
    }
    return colunasPadrao;
  }

  async function adicionarAtalho(identificadorRecurso: string, empresas:number[]) {
    const retorno = await new ServicoSistema().adicionarAtalho(identificadorRecurso, empresas);
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      apresentarMensagemSucesso(retorno.mensagem);
      const atalhos = await new ServicoSistema().obterMeusAtalhos();
      storeSistema.mutations.atualizarMeusAtalhos(atalhos);
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  async function removerAtalho(identificadorRecurso: string, empresas:number[]) {
    const retorno = await new ServicoSistema().removerAtalho(identificadorRecurso, empresas);
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      apresentarMensagemSucesso(retorno.mensagem);
      const atalhos = await new ServicoSistema().obterMeusAtalhos();
      storeSistema.mutations.atualizarMeusAtalhos(atalhos);
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  function telaOperacaoIncluir():ITelaOperacao {
    const telaOperacao = {} as ITelaOperacao;
    telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
    telaOperacao.listaPermissoesDados = telaBase.listaPermissoesDados;
    telaOperacao.codigoRegistro = 0;
    telaOperacao.empresaSelecionada = telaBase.empresasSelecionadas[0];
    telaOperacao.codigoRegistroDuplicar = 0;
    telaOperacao.codigoRegistroPai = 0;
    return telaOperacao;
  }

  function telaOperacaoEditar(codigo: number):ITelaOperacao {
    const telaOperacao = {} as ITelaOperacao;
    telaOperacao.tipoPermissaoDados = EPermissaoDados.Visualizar;
    telaOperacao.listaPermissoesDados = telaBase.listaPermissoesDados;
    telaOperacao.codigoRegistro = codigo;
    telaOperacao.empresaSelecionada = telaBase.empresasSelecionadas[0];
    telaOperacao.codigoRegistroDuplicar = 0;
    telaOperacao.codigoRegistroPai = 0;
    return telaOperacao;
  }

  function preencherDadosRota(): void {
    const route = useRoute();
    telaBase.titulo = String(route.meta.titulo);
    telaBase.identificadorRecurso = String(route.meta.identificadorRecurso);
    telaBase.identificadorPermissao = String(route.meta.identificadorPermissao);
  }

  return {
    telaBase,
    obterEmpresas,
    obterPermissoes,
    preencherEmpresasDisponiveis,
    preencherEmpresasComEstrategiaPermissaoDados,
    preencherEmpresasComEstrategiaPermissao,
    preencherPermissoesDados,
    preencherPermissoes,
    defineEmpresasSelecionadasComPermissao,
    defineEmpresasSelecionadasCadastroCompartilhado,
    filtrarPermissaoDadosUsuario,
    filtrarPermissaoDadosUsuarioMultiEmpresas,
    apresentarMensagemSucesso,
    apresentarMensagemAlerta,
    apresentarMensagemErro,
    montaObjetoPreferencia,
    salvarPreferencias,
    salvarPreferenciasFiltros,
    salvarPreferenciasOrdenacao,
    salvarPreferenciasGrade,
    carregarPreferenciasFiltros,
    carregarPreferenciasOrdenacao,
    carregarPreferenciasGrade,
    adicionarAtalho,
    removerAtalho,
    verificaConceitoParaApresentarEmpresas,
    verificaAutorizacao,
    montaOpcoesComListaItemGenerico,
    montaOpcoesComListaItemGenericoTexto,
    montaOpcoesComListaConsultaRapida,
    montaOpcoesComListaConsultaRapidaString,
    telaOperacaoIncluir,
    telaOperacaoEditar,
    preencherDadosRota,
  };
}
