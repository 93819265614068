import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { IParametrosConsulta } from '../models/Consulta/IParametrosConsulta';
import { IFiltroGenerico } from '../models/BuscaAvancada/IFiltroGenerico';
import { IParametrosConsultaRapida } from '../models/Consulta/IParametrosConsultaRapida';
import { IItemConsultaRapida } from '../models/Consulta/IItemConsultaRapida';
import { IListaPaginada } from '../models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '../models/Consulta/PropriedadeConsulta';
import { IRetornoRelatorio } from '../models/IRetornoRelatorio';
import { ETipoArquivo } from '../models/Enumeradores/ETipoArquivo';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import router from '@/router';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';

const gerenteAutenticacao = new GerenteAutenticacao();
const { apresentarMensagemAlerta } = useTelaBase();

const authHeader = () => ({
  Authorization: `Bearer ${gerenteAutenticacao.obtemTokenAutenticado()}`,
});

export default class ApiERP {
  protected instance: AxiosInstance;

  protected readonly baseURL: string;

  public constructor(requisicaoSistema?:boolean) {
    this.baseURL = storeSistema.state.configuracaoApp.urlApi;
    if (requisicaoSistema !== undefined && requisicaoSistema === true) {
      this.instance = axios.create({
        baseURL: this.baseURL,
        timeout: 480000,
        headers: {
          Authorization: `Bearer ${gerenteAutenticacao.obtemTokenAutenticado()}`,
          'Content-Type': 'application/json',
          TokenRequisicaoSistema: 'GVCZWZTRWT46HTAKIAXP',
        },
      });
    } else {
      this.instance = axios.create({
        baseURL: this.baseURL,
        timeout: 480000,
        headers: {
          Authorization: `Bearer ${gerenteAutenticacao.obtemTokenAutenticado()}`,
          'Content-Type': 'application/json',
        },
      });
    }
    this.interceptaAxios();
  }

  public get(path?: string): AxiosPromise<unknown> {
    return this.instance({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  public post(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  public upload(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
    });
  }

  public patch(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  public delete(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  public put(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  public async obterPropriedadesConsulta(path: string): Promise<IPropriedadeConsulta[]> {
    const result: any = await this.get(`${path}/propriedades-consulta`);
    return result.data;
  }

  public async consultaRapida(path: string, parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.get(`${path}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaAvancada(path: string, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    const parametrosEmpresas = this.obterParametroEmpresas(parametrosConsulta.empresas);
    const parametrosAdicionais = this.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);

    const result: any = await this.get(`${path}${parametrosEmpresas}${parametrosAdicionais}`);
    const listaPaginada = {} as IListaPaginada;
    listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
    listaPaginada.dados = result.data;
    return listaPaginada;
  }

  public async relatorioPadrao(path: string, tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta,
    filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    const parametrosEmpresas = this.obterParametroEmpresas(parametrosConsulta.empresas);
    let parametrosAdicionais = this.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
    if (codigosSelecionados !== undefined) {
      parametrosAdicionais += this.obterParametroCodigosSelecionados(codigosSelecionados);
    }
    const result: any = await this.get(`${path}/relatorio-padrao/${tipoArquivo}${parametrosEmpresas}${parametrosAdicionais}`);
    return result.data;
  }

  public obterParametrosAdicionaisConsulta(parametrosConsulta: IParametrosConsulta, filtros?: Array<IFiltroGenerico>) : string {
    let parametrosAdicionais = '';
    if (filtros !== undefined) {
      const jsonFiltros = JSON.stringify(filtros);
      parametrosAdicionais += `&Filtros=${jsonFiltros}`;
    }
    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += `&Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.valorBuscaRapida !== undefined) {
      parametrosAdicionais += `&ValorBuscaRapida=${parametrosConsulta.valorBuscaRapida.replace('&', '@amp')}`;
    }
    if (parametrosConsulta.codigosSelecionados !== undefined) {
      parametrosConsulta.codigosSelecionados.forEach((codigo) => {
        parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
      });
    }
    return parametrosAdicionais;
  }

  public obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida) : string {
    let parametrosAdicionais = '';

    if (parametrosConsultaRapida.empresas !== undefined && parametrosConsultaRapida.empresas.length > 0) {
      parametrosConsultaRapida.empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    if (parametrosConsultaRapida.valor !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      if (parametrosConsultaRapida.valor !== undefined && parametrosConsultaRapida.valor !== '' && !parametrosConsultaRapida.filtrarPorCodigo) {
        parametrosConsultaRapida.valor.replace('&', '@amp');
      }
      parametrosAdicionais += `Valor=${parametrosConsultaRapida.valor}`;
    }

    if (parametrosConsultaRapida.valores !== undefined) {
      parametrosConsultaRapida.valores.forEach((valor) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Valores=${encodeURIComponent(valor)}`;
      });
    }

    if (parametrosConsultaRapida.recursoAssociado !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `RecursoAssociado=${parametrosConsultaRapida.recursoAssociado}`;
    }

    if (parametrosConsultaRapida.quantidadeRegistros !== undefined && parametrosConsultaRapida.quantidadeRegistros > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QuantidadeRegistros=${parametrosConsultaRapida.quantidadeRegistros}`;
    }

    if (parametrosConsultaRapida.filtrarPorCodigo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `FiltrarPorCodigo=${parametrosConsultaRapida.filtrarPorCodigo}`;
    }

    if (parametrosConsultaRapida.filtrarPorVariosCodigos !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `FiltrarPorVariosCodigos=${parametrosConsultaRapida.filtrarPorVariosCodigos}`;
    }

    if (parametrosConsultaRapida.filtrarTextoExato !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `FiltrarTextoExato=${parametrosConsultaRapida.filtrarTextoExato}`;
    }

    if (parametrosConsultaRapida.apenasAtivos !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `ApenasAtivos=${parametrosConsultaRapida.apenasAtivos}`;
    }
    return parametrosAdicionais;
  }

  public obterParametroEmpresas(empresas: Array<number>) : string {
    let parametroEmpresas = '';
    if (empresas !== undefined && empresas.length > 0) {
      empresas.forEach((codigoEmpresa) => {
        parametroEmpresas += (parametroEmpresas !== '' ? '&' : '?');
        parametroEmpresas += `Empresas=${codigoEmpresa}`;
      });
    }
    return parametroEmpresas;
  }

  public obterParametroCodigosSelecionados(codigosSelecionados: Array<number>) : string {
    let parametrosCodigosSelecionados = '';
    if (codigosSelecionados !== undefined) {
      const jsonCodigos = JSON.stringify(codigosSelecionados);
      parametrosCodigosSelecionados += `&CodigosSelecionados=${jsonCodigos}`;
    }
    return parametrosCodigosSelecionados;
  }

  public interceptaAxios():void {
    /**
     * axios interceptors runs before and after a request, letting the developer modify req,req more
     * For more details on axios interceptor see https://github.com/axios/axios#interceptors
     */
    this.instance.interceptors.request.use((config) => {
      // do something before executing the request
      // For example tag along the bearer access token to request header or set a cookie
      const requestConfig = config;
      requestConfig.headers.Authorization = `Bearer ${gerenteAutenticacao.obtemTokenAutenticado()}`;

      return requestConfig;
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */

        if (!gerenteAutenticacao.verificaAutenticacao()) {
          router.push({ name: 'Login' });
          return Promise.reject(error);
        }

        const { response } = error;
        const originalRequest = error.config;

        if (response) {
          if (response.status === 401) {
            gerenteAutenticacao.removerDadosAutenticacao();
            router.push({ name: 'Login' });
          } else if (response.status === 403) {
            router.push({ name: 'SemPermissao' });
          } else if (response.status === 404) {
            router.push({ name: 'PaginaNaoEncontrada' });
          } else if (response.status === 500) {
            apresentarMensagemAlerta('Ops, algo de errado aconteceu! Por favor aguarde alguns instantes e tente novamente, caso o problema continue entre em contato com o nosso suporte.');
          } else {
            return originalRequest;
          }
        } else {
          router.push({ name: 'ErroRequisicao' });
        }

        return Promise.reject(error);
      },
    );
  }
}
