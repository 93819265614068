
import { defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  components: {
    InlineSvg,
  },
  props: {
    nome: {
      type: String,
      default: '',
    },
  },
});
